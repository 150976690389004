import { render, staticRenderFns } from "./Phototask_dmx.vue?vue&type=template&id=5c642150&scoped=true"
import script from "./Phototask_dmx.vue?vue&type=script&lang=js"
export * from "./Phototask_dmx.vue?vue&type=script&lang=js"
import style0 from "./Phototask_dmx.vue?vue&type=style&index=0&id=5c642150&prod&lang=scss&scoped=true"
import style1 from "./Phototask_dmx.vue?vue&type=style&index=1&id=5c642150&prod&scoped=true&lang=css"
import style2 from "./Phototask_dmx.vue?vue&type=style&index=2&id=5c642150&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c642150",
  null
  
)

/* custom blocks */
import block0 from "./Phototask_dmx.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports